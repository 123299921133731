const { DateTime } = require('luxon');

(function () {
    'use strict';

    bindhq.ns('forms.quotes');

    /**
     * An editable quote model has been closed
     *
     * @param {jQuery} container
     */
    bindhq.forms.quotes.onClose = function (container) {
        container.remove();
    };

    /**
     * A bind radio button has been selected, enable bind button
     *
     * @param {jQuery} link
     */
    bindhq.forms.quotes.onQuoteBindSelect = function (link) {
        link.attr({ disabled: false });
    };

    /**
     * The ajax request for the bind form has returned, show modal
     */
    bindhq.forms.quotes.onBindFormLoaded = function (modal) {
        bindhq.modalAjax.helper.initModalDetectScroll(modal);
    };

    /**
     * Takes an element, finds the nearest form and creates the intabAjax anchor from that.
     *
     * @param {jQuery} anchor
     * @returns {jQuery}
     */
    bindhq.forms.quotes.createAjaxTab = function (anchor) {
        const url = anchor.attr('href');

        return $('<a></a>').data('intabajax', 'quotes').attr('href', url);
    };

    /**
     * @param {jQuery} modal
     * @param {jQuery} anchor
     * @param {jQuery.Event} evt
     */
    bindhq.forms.quotes.onProceedClick = function (modal, anchor, evt) {
        const proceed = $(evt.currentTarget);

        bindhq.intabAjax.loadContent(anchor, {
            button: proceed,
            success: function () {
                modal.modal('hide');
            },
        });
    };

    /**
     * The bind button has been clicked, show bind form in modal
     *
     * @param {{Object}} evt
     */
    bindhq.forms.quotes.onBindClick = function (evt) {
        const self = bindhq.forms.quotes;
        const element = $(evt.currentTarget);
        const group = element.closest('.btn-group');
        const button = group.find('.btn:first');
        const modal = group.next();
        const anchor = self.createAjaxTab(element);
        const onProceedClick = _.partial(self.onProceedClick, modal, anchor);

        if (modal.hasClass('modal')) {
            $('.btn-primary', modal).unbind().click(onProceedClick);
            modal.modal('show');
        } else {
            group.removeClass('open');
            bindhq.intabAjax.loadContent(anchor, { button: button });
        }
    };

    /**
     * Inits ability to bind a quote to the application to create a policy
     *
     */
    bindhq.forms.quotes.initBinding = function () {
        const bindClick = bindhq.util.noDefault(
            bindhq.forms.quotes.onBindClick,
        );

        $('.bind', this).click(bindClick);
    };

    bindhq.forms.quotes.updateCarrierFilter = function (
        container,
        carrier,
        carrierstate,
    ) {
        const carrierId = carrier.select2('val');

        carrierstate.data('ajaxurl', function () {
            return carrierstate
                .get(1)
                .dataset.ajaxurlPattern.replace(/{carrierId}/, carrierId);
        });
    };

    bindhq.forms.quotes.carrierChanged = function (
        container,
        carrier,
        carrierstate,
    ) {
        const self = bindhq.forms.quotes;
        const carrierId = carrier.select2('val');
        const toggle = carrierId ? 'enable' : 'disable';

        carrierstate.select2('val', null, true);
        carrierstate.select2(toggle);

        self.updateCarrierFilter(container, carrier, carrierstate);
        self.initSetCarrierstateIdByInsuredState(
            container,
            carrier,
            carrierstate,
        );
    };

    bindhq.forms.quotes.initSetCarrierstateIdByInsuredState = function (
        container,
        carrier,
        carrierstate,
    ) {
        const applicationId = container.data('applicationid');
        const carrierId = carrier.select2('val');

        if (!carrierId || !applicationId) {
            return;
        }

        const url = bindhq.forms.quotes.getCarrierstateIdByInsuredStateUrl();
        const success = _.partial(
            bindhq.forms.quotes.setCarrierstateIdByInsuredState,
            carrierstate,
        );
        const data = {
            carrierId: carrierId,
            applicationId: applicationId,
        };

        $.getJSON(url, data, success);
    };

    bindhq.forms.quotes.getCarrierstateIdByInsuredStateUrl = function () {
        return '/secure/partners/carriers/carrierstates/insuredstate.json';
    };

    bindhq.forms.quotes.setCarrierstateIdByInsuredState = function (
        container,
        data,
        textStatus,
        jqXHR,
    ) {
        const carrierState = JSON.parse(jqXHR.responseText);

        if (carrierState) {
            container.select2('data', carrierState);
        } else {
            container.select2('data', null);
        }

        container.trigger('change');
    };

    /**
     * @param {jQuery} termEffective
     * @param {jQuery} termExpiry
     * @param {jQuery.Event} evt
     */
    bindhq.forms.quotes.onTermHelperClick = function (
        termEffective,
        termExpiry,
        evt,
    ) {
        const anchor = $(evt.currentTarget);
        const months = parseInt(anchor.text(), 10);
        const effective = termEffective.datepicker('getDate');
        const expiry = DateTime.fromISO(effective).plus({ months }).toJSDate();

        termExpiry.datepicker('setDate', expiry);
    };

    /**
     * @param {jQuery} container
     */
    bindhq.forms.quotes.initTermHelpers = function (container) {
        const termAutofillTpl =
            '<div class="row">' +
            '<div class="col-12 span12 term-autofill">' +
            'Set to ' +
            '<a href="#">3</a> / ' +
            '<a href="#">6</a> / ' +
            '<a href="#">12</a> / ' +
            '<a href="#">18</a> / ' +
            '<a href="#">24</a> ' +
            'months' +
            '</div>' +
            '</div>';

        const html = bindhq.util.template(termAutofillTpl, {});
        const termEffective = $('.term-effective', container);
        const termExpiry = $('.term-expiry', container);
        const onClick = _.partial(
            bindhq.forms.quotes.onTermHelperClick,
            termEffective,
            termExpiry,
        );

        $(html)
            .insertAfter(termEffective.next())
            .on('click', 'a', bindhq.util.noDefault(onClick));
    };

    /**
     * @param {jQuery} container
     */
    bindhq.forms.quotes.initCarrierState = function (container) {
        const carrier = $('#quote_carrier', container);
        const carrierstate = $('.carrierstate', container);

        carrier.on(
            'change',
            _.partial(
                bindhq.forms.quotes.carrierChanged,
                container,
                carrier,
                carrierstate,
            ),
        );

        bindhq.forms.quotes.updateCarrierFilter(
            container,
            carrier,
            carrierstate,
        );
    };

    /**
     * When a carrier is selected we need to update the filter on the contacts
     *
     * @param {jQuery} carrier
     * @param {jQuery} contacts
     * @param {String} carrierId
     */
    bindhq.forms.quotes.onCarrierSelect = function (
        carrier,
        contacts,
        carrierId,
    ) {
        contacts.data('ajaxurl', function () {
            return contacts
                .data('ajaxurl-pattern')
                .replace(/{carrierId}/, carrierId);
        });
    };

    /**
     * Carrier contacts need to be filtered by carrier
     *
     * @param {jQuery} container
     */
    bindhq.forms.quotes.initCarrierContacts = function (container) {
        const carrier = $('#quote_carrier', container);
        const contacts = $('#quote_carrierContact', container);

        bindhq.forms.ajaxselect.dependsOn(
            carrier,
            contacts,
            bindhq.forms.quotes.onCarrierSelect,
        );
    };

    bindhq.forms.quotes.initLinksToQuotes = function () {
        $('.quote-table', this).each(bindhq.forms.quotes.initBinding);
    };

    bindhq.forms.quotes.onSave = function () {
        if (bindhq.tabs.exists('quotes')) {
            bindhq.tabs.refresh('quotes');
        }
    };

    bindhq.forms.quotes.MAXIMUM_QUOTE = 100000000;

    bindhq.forms.quotes.validate = function ($container) {
        if (!$container.valid()) {
            return false;
        }

        return bindhq.forms.quotes.validateQuotePremium($container);
    };

    bindhq.forms.quotes.validateQuotePremium = function ($container) {
        const $quotesInput = $container.find("[data-name='premium']");
        const premium = parseFloat($quotesInput.val());
        if (premium > bindhq.forms.quotes.MAXIMUM_QUOTE) {
            swal(
                'Quote Premium is over the maximum value. Please enter a premium lower than ' +
                    bindhq.forms.quotes.MAXIMUM_QUOTE +
                    '.',
            );

            return false;
        }

        return true;
    };

    bindhq.forms.quotes.marketingCompanyChanged = function (
        marketingCompany,
        carrier,
    ) {
        carrier.data('filter', {
            marketing_company_id: marketingCompany.select2('val'),
        });
    };

    bindhq.forms.quotes.initMarketCarrierFields = function (container) {
        const marketingCompany = $('.marketing-company', container);
        const carrier = $('.carrier', container);
        const onChange = this.marketingCompanyChanged;

        bindhq.forms.ajaxselect.dependsOn(marketingCompany, carrier, onChange);

        onChange(marketingCompany, carrier);
    };

    bindhq.forms.quotes.init = function () {
        const self = bindhq.forms.quotes;
        const container = $(this);

        self.initTermHelpers(container);
        self.initCarrierState(container);
        self.initCarrierContacts(container);
        self.initMarketCarrierFields(container);

        if (container.closest('.tab-pane').length > 0) {
            container.formAjax({
                onBeforeSubmit: _.partial(
                    bindhq.forms.quotes.validate,
                    container,
                ),
                success: self.onSave,
                lockOnSuccess: true,
            });
        }
    };
})();
