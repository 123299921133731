(function () {
    'use strict';

    bindhq.nsIn('maps', {
        /**
         * @type {Object}
         */
        cache: {},

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        titleFor: function (result) {
            if (result.agency) {
                return 'Agency: ' + result.agency.name;
            } else if (result.carrier) {
                return 'Carrier: ' + result.carrier.name;
            } else if (result.insured_address) {
                return (
                    'Insured Address: ' + result.insured_address.insured.name
                );
            } else if (result.insured) {
                return 'Insured: ' + result.insured.name;
            } else if (result.sltwo) {
                return 'Sltwo Company: ' + result.sltwo.name;
            } else if (result.additional_insured) {
                return 'Additional Insured: ' + result.additional_insured.name;
            } else if (result.finance) {
                return 'Finance Company: ' + result.finance.name;
            }

            return '';
        },

        /**
         * @param {Object} entity
         *
         * @return {String}
         */
        addressFor: function (entity) {
            return (
                '<div class="address">' +
                '<div>' +
                (entity.address_line1 || '') +
                '</div>' +
                '<div>' +
                (entity.address_line2 || '') +
                '</div>' +
                '<div>' +
                (entity.address_line3 || '') +
                '</div>' +
                '<div>' +
                (entity.state || '') +
                '</div>' +
                '<div>' +
                (entity.zip || '') +
                '</div>' +
                '</div>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        streetviewFor: function (result) {
            const url =
                'https://maps.googleapis.com/maps/api/streetview?size=150x150&location=' +
                result.latitude +
                ',' +
                result.longitude +
                '&fov=90&heading=235&pitch=10&sensor=false';

            return (
                '<div class="streetview">' +
                '<img src="' +
                url +
                '" />' +
                '</div>'
            );
        },

        /**
         * @param {Object} result
         * @param {Object} entity
         *
         * @return {String}
         */
        addressViewFor: function (result, entity) {
            return (
                '<div class="address-view">' +
                this.streetviewFor(result) +
                this.addressFor(entity) +
                '</div>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForAgency: function (result) {
            const agency = result.agency;

            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, agency) +
                '<p><a class="link" target="_blank" href="/secure/partners/agencies/' +
                agency.id +
                '">View Agency</a></p>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForCarrier: function (result) {
            const carrier = result.carrier;

            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, carrier) +
                '<p><a class="link" target="_blank" href="/secure/partners/carriers/' +
                carrier.id +
                '">View Carrier</a></p>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForInsuredAddress: function (result) {
            const address = result.insured_address;
            const insured = address.insured;

            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, address) +
                '<h5>Insured: ' +
                insured.name +
                '</h5>' +
                this.addressFor(insured) +
                '<p><a class="link" href="#">View Insured</a></p>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForInsured: function (result) {
            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, result.insured)
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForSltwoCompany: function (result) {
            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, result.sltwo)
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForAdditionalInsured: function (result) {
            const insured = result.additional_insured;

            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, insured) +
                '<h5>Application #' +
                insured.application.id +
                '</h5>' +
                '<a class="link" target="_blank" href="/secure/applications/' +
                insured.application.id +
                '">View Application</a>'
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentForFinanceCompany: function (result) {
            const finance = result.finance;

            return (
                '<h4>' +
                this.titleFor(result) +
                '</h4>' +
                this.addressViewFor(result, finance)
            );
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        contentFor: function (result) {
            if (result.agency) {
                return this.contentForAgency(result);
            } else if (result.carrier) {
                return this.contentForCarrier(result);
            } else if (result.insured_address) {
                return this.contentForInsuredAddress(result);
            } else if (result.insured) {
                return this.contentForInsured(result);
            } else if (result.sltwo) {
                return this.contentForSltwoCompany(result);
            } else if (result.additional_insured) {
                return this.contentForAdditionalInsured(result);
            } else if (result.finance) {
                return this.contentForFinanceCompany(result);
            }

            return '';
        },

        /**
         * @param {Object} result
         *
         * @return {String}
         */
        idFor: function (result) {
            const types = [
                'agency',
                'carrier',
                'insured_address',
                'insured',
                'sltwo',
                'additional_insured',
                'finance',
            ];

            const toId = function (acc, type) {
                return result[type] ? type + '-' + result[type].id : acc;
            };

            return types.reduce(toId, null);
        },

        /**
         * @param {google.maps.Map} map
         * @param {google.maps.marker.AdvancedMarkerElement} marker
         * @param {google.maps.InfoWindow} infoWindow
         * @param {Object} result
         */
        onMarkerClick: function (map, marker, infoWindow, result) {
            const content =
                '<div class="map-info-content">' +
                this.contentFor(result) +
                '</div>';

            infoWindow.setContent(content);
            infoWindow.open(map, marker);
        },

        /**
         * @param {Array} results
         *
         * @return {Array}
         */
        filterNew: function (results) {
            const idFor = this.idFor;
            const cache = this.cache;

            const toNew = function (acc, result) {
                const id = idFor(result);

                if (!cache[id]) {
                    cache[id] = true;
                    return acc.concat([result]);
                }

                return acc;
            };

            return results.reduce(toNew, []);
        },

        /**
         * @param {google.maps.Map} map
         * @param {Array} results
         */
        onMapDataLoaded: function (map, results) {
            const infoWindow = new google.maps.InfoWindow();
            const newResults = this.filterNew(results);

            for (const i in newResults) {
                const result = results[i];
                const config = {
                    map: map,
                    title: this.titleFor(result),
                    position: new google.maps.LatLng(
                        result.latitude,
                        result.longitude,
                    ),
                };

                bhqGoogleMaps(() => {
                    const marker = new google.maps.marker.AdvancedMarkerElement(
                        config,
                    );
                    const onClick = _.partial(
                        this.onMarkerClick,
                        map,
                        marker,
                        infoWindow,
                        result,
                    );

                    google.maps.event.addListener(marker, 'click', onClick);
                });
            }
        },

        /**
         * @param {google.maps.Map} map
         * @param {String} url
         */
        onDragEnd: function (map, url) {
            this.loadMapData(map, url);
        },

        /**
         * @param {google.maps.Map} map
         * @param {String} url
         */
        onZoomChanged: function (map, url) {
            this.loadMapData(map, url);
        },

        /**
         * @param {google.maps.Map} map
         */
        hideLoading: function (map) {
            $(map.getDiv())
                .closest('.map-container')
                .removeClass('map-data-loading');
        },

        /**
         * @param {google.maps.Map} map
         */
        showLoading: function (map) {
            $(map.getDiv())
                .closest('.map-container')
                .addClass('map-data-loading');
        },

        /**
         * @param {google.maps.Map} map
         */
        onAjaxComplete: function (map) {
            this.hideLoading(map);
        },

        /**
         * @param {google.maps.Map} map
         * @param {Object} config
         */
        ajax: function (map, config) {
            if (!config.complete) {
                config.complete = _.partial(this.onAjaxComplete, map);
            }

            this.showLoading(map);

            return $.ajax(config);
        },

        /**
         * @param {google.maps.Map} map
         * @param {String} url
         */
        loadMapData: function (map, url) {
            const bounds = map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            const config = {
                url: url,
                data: {
                    nwLatitude: ne.lat(),
                    nwLongitude: ne.lng(),
                    seLatitude: sw.lat(),
                    seLongitude: sw.lng(),
                },
                success: _.partial(this.onMapDataLoaded, map),
            };

            this.ajax(map, config);
        },

        /**
         * @param {jQuery} container
         * @param {google.maps.Map} map
         */
        onMapLoaded: function (container, map) {
            container.closest('.map-container').removeClass('map-not-loaded');

            if (container.data('map-streetview')) {
                const sv = map.getStreetView();

                sv.setPosition(map.getBounds().getCenter());
                sv.setVisible(true);
            }
        },

        /**
         * @param {google.maps.Map} map
         * @param {jQuery} container
         */
        initMapUrlData: function (map, container) {
            const url = container.data('map-url-data');
            const onMapLoad = _.partial(this.loadMapData, map, url);
            const onDragEnd = _.partial(this.onDragEnd, map, url);
            const onZoomChanged = _.partial(this.onZoomChanged, map, url);

            google.maps.event.addListenerOnce(map, 'idle', onMapLoad);
            google.maps.event.addListener(
                map,
                'dragend',
                _.debounce(onDragEnd, 1000),
            );
            google.maps.event.addListener(
                map,
                'zoom_changed',
                _.debounce(onZoomChanged, 1500),
            );
        },

        /**
         * @param {google.maps.Map} map
         * @param {jQuery} container
         * @param {Number} latitude
         * @param {Number} longitude
         */
        initMapCenterMarker: function (map, container, latitude, longitude) {
            const config = {
                map: map,
                title: container.data('map-center-title'),
                position: new google.maps.LatLng(latitude, longitude),
            };

            bhqGoogleMaps(() => {
                new google.maps.marker.AdvancedMarkerElement(config);
            });
        },

        /**
         * @param {DOMElement} element
         * @param {Number} latitude
         * @param {Number} longitude
         */
        loadMap: function (element, latitude, longitude) {
            const container = $(element);
            const config = {
                center: new google.maps.LatLng(latitude, longitude),
                mapId: 'BINDHQ_MAP',
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoom: container.data('map-zoom') || 12,
            };
            const map = new google.maps.Map(element, config);

            $(element).data('gmap', map);

            google.maps.event.addListenerOnce(
                map,
                'idle',
                _.partial(this.onMapLoaded, container, map),
            );

            if (container.data('map-url-data')) {
                this.initMapUrlData(map, container);
            }

            if (
                container.data('map-byarea-boundaries') &&
                container.data('map-byarea-data')
            ) {
                bindhq.maps.byArea.initContainer(map, container);
            }

            if (container.data('map-center') === 'marker') {
                this.initMapCenterMarker(map, container, latitude, longitude);
            }
        },

        /**
         * @param {DOMElement} element
         * @param {Object} position
         */
        onCurrentPosition: function (element, position) {
            this.loadMap(
                element,
                position.coords.latitude,
                position.coords.longitude,
            );
        },

        /**
         * @param {DOMElement} element
         */
        onCurrentPositionError: function (element) {
            const tustin = {
                latitude: 33.7397,
                longitude: -117.8136,
            };

            this.loadMap(element, tustin.latitude, tustin.longitude);
        },

        /**
         * @param {jQuery} container
         */
        init: function (container) {
            const element = container.get(0);
            const latitude = container.data('map-latitude');
            const longitude = container.data('map-longitude');

            if (latitude && longitude) {
                this.loadMap(element, latitude, longitude);
            } else {
                navigator.geolocation.getCurrentPosition(
                    _.partial(this.onCurrentPosition, element),
                    _.partial(this.onCurrentPositionError, element),
                );
            }

            $('.map-menu').toggleClassOnClick('map-menu-open');
        },
    });
})();
