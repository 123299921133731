const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.ns('tableListing');

    bindhq.tableListing.onPin = function (container, evt) {
        const pin = $(evt.target);
        const pinned = $(pin).data('pinned');

        const pinUrl = $(pin).data('pin-url');
        const unpinUrl = $(pin).data('unpin-url');

        const url = pinned ? unpinUrl : pinUrl;

        const success = function () {
            $(container).trigger('refreshPage');
        };
        $.get(url, {}, success);
    };

    bindhq.tableListing.onClickRow = function (container, evt) {
        if (
            !$(evt.target).hasClass('dropdown-toggle') &&
            !$(evt.target).closest('a').hasClass('btn') &&
            !$(evt.target).closest('a').hasClass('entity-link')
        ) {
            const row = $(evt.currentTarget);
            const subRowClass = row.data('sub-row');
            const subRowSelector = '.' + subRowClass;

            if ($(subRowSelector, container).length === 0) {
                const newRow = $('<tr></tr>')
                    .addClass('sub-row')
                    .addClass(subRowClass);

                row.after(newRow);
            }

            const subRow = $(subRowSelector, container);

            if (row.hasClass('expanded')) {
                bindhq.tableListing.collapseRow(container, subRow, row);
            } else {
                bindhq.tableListing.expandRow(container, subRow, row, evt);
            }
        }
    };

    bindhq.tableListing.expandRow = function (table, subrow, row, evt) {
        table.find('.expanded').removeClass('expanded');

        $(row).toggleClass('expanded');
        $(subrow).toggleClass('expanded');

        if (row.hasClass('expandable-dynamic')) {
            if (!subrow.hasClass('data-loaded')) {
                bindhq.tableListing.loadDetailData(row, subrow);
            }
        }

        if (table.find('.expanded').length && !table.hasClass('row-expended')) {
            table.addClass('row-expanded');
        } else {
            table.removeClass('row-expanded');
        }
    };

    /**
     *
     */
    bindhq.tableListing.loadDetailData = function (row, subrow) {
        const url = row.data('detail-url');
        const data = row.data('detail-data');
        const container = subrow.find('.dynamic-content').get(0)
            ? subrow.find('.dynamic-content')
            : subrow;
        const config = {
            url: url,
            data: data,
            success: function (data) {
                container.html(data);
                subrow.addClass('data-loaded');
                $('.contact-selector-container .select2', container).select2();
            },
            error: function () {
                container.html(
                    '<td><p>Transaction could not be loaded</p></td>',
                );
            },
        };

        container.html(
            '<td colspan="999" class="align-center"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span></td>',
        );

        $.ajax(config);
    };

    bindhq.tableListing.collapseRow = function (table, subrow, row) {
        table.find('.expanded').removeClass('expanded');
        table.removeClass('row-expanded');
    };

    bindhq.tableListing.onToggleFilter = function (container, evt) {
        $(container)
            .closest('.table-listing-wrapper')
            .toggleClass('table-listing-tools-on');
    };

    bindhq.tableListing.onClickOverlay = function (container, evt) {
        if ($(evt.target).hasClass('table-listing-tools')) {
            bindhq.tableListing.onToggleFilter(container);
        }
    };

    bindhq.tableListing.onApplyFilter = function (container, table) {
        const form =
            container.closest('form').get(0) || container.find('form').get(0);

        $(form).validate();

        if (form && 'true' === form.dataset.noAjax) {
            form.submit();
            return;
        }

        const sidebarValid = $('.sidebar-filters')
            .find(
                '.form-control:not(.select2-container):not(.ts-wrapper), select.tomselected',
            )
            .toArray()
            .every((element) => {
                // Remove this block when feature flag removed, it's required because the element
                // is only part of a form under the feature flag, and will error if not part of a form.
                if (!bindhq.isGranted('FEATURE_FILTERS_ON_TRANSACTIONS')) {
                    return true;
                }

                return $(element).valid();
            });

        if (sidebarValid) {
            $(container)
                .closest('.table-listing-wrapper')
                .removeClass('table-listing-tools-on');

            table.trigger('refreshPage');
        }
    };

    bindhq.tableListing.updateFilterBadges = function (container) {
        $('.current-filters', container).empty();

        const filters = $('.sidebar-filters [data-indicated]', container);

        _.each(filters, function (filter) {
            bindhq.tableListing.addByType(filter, container);
        });
    };

    bindhq.tableListing.addByType = function (filter, container) {
        const input = $(filter);
        const id = input.attr('id');
        const removable =
            !input.prop('required') &&
            !input.prop('readonly') &&
            !input.data('readonly');
        let text;

        if (input.data('select2')) {
            const select2Value = input.data('select2-value')
                ? input.data('select2-value')
                : 'name';
            if (Array.isArray(input.select2('data'))) {
                if (bindhq.isGranted('FEATURE_MULTIPLE_FILTERS_LIST')) {
                    const textValues = [];
                    _.each(input.select2('data'), function (obj) {
                        let itemText;
                        const format = input.data('format');

                        if (format) {
                            const tempDiv = document.createElement('div');
                            tempDiv.innerHTML = _.template(format)({
                                item: obj,
                            });
                            itemText = tempDiv.textContent
                                .replace(/\s+/g, ' ')
                                .trim();
                        } else if (select2Value && obj[select2Value]) {
                            itemText = obj[select2Value];
                        } else {
                            itemText = obj.name || obj.text;
                        }

                        if (itemText) {
                            textValues.push(itemText);
                        }
                    });
                    if (textValues.length > 0) {
                        let indicatedAttr = input.data('indicated');
                        if (
                            textValues.length > 1 &&
                            input.data('indicated-plural')
                        ) {
                            indicatedAttr = input.data('indicated-plural');
                        }
                        const combinedText =
                            indicatedAttr + textValues.join(', ');
                        bindhq.tableListing.addCurrentFilter(
                            id,
                            combinedText,
                            null,
                            removable,
                            container,
                        );
                    }
                } else {
                    _.each(input.select2('data'), function (obj) {
                        text = obj[select2Value] ? obj[select2Value] : obj.text;
                        text = text ? input.data('indicated') + text : text;
                        const value = obj.id;
                        bindhq.tableListing.addCurrentFilter(
                            id,
                            text,
                            value,
                            removable,
                            container,
                        );
                    });
                }
            } else {
                if (input.select2('data')) {
                    const value = input.select2('data').id;
                    const obj = input.select2('data');
                    if (value) {
                        text = obj[select2Value] ? obj[select2Value] : obj.text;
                        text = text ? input.data('indicated') + text : text;
                        bindhq.tableListing.addCurrentFilter(
                            id,
                            text,
                            null,
                            removable,
                            container,
                        );
                    }
                }
            }
        } else if (input.attr('type') === 'radio') {
            if (input.prop('checked')) {
                text = input.data('indicated');
                bindhq.tableListing.addCurrentFilter(
                    id,
                    text,
                    null,
                    removable,
                    container,
                );
            }
        } else if (input.attr('type') === 'checkbox') {
            if (input.prop('checked')) {
                text = input.data('indicated');
                bindhq.tableListing.addCurrentFilter(
                    id,
                    text,
                    null,
                    removable,
                    container,
                );
            }
        } else if (input.find('input').attr('type') === 'checkbox') {
            _.each(input.find('input'), function (item) {
                bindhq.tableListing.addByType(item, container);
            });
        } else if (filter.tomselect) {
            const value = filter.tomselect.getValue();
            if (value && !_.isEqual(value, [])) {
                let indicatedAttr = input.data('indicated');
                if (value.length > 1 && input.data('indicated-plural')) {
                    indicatedAttr = input.data('indicated-plural');
                }

                let text = filter.tomselect.getItem(value).innerHTML;
                if (undefined === text) {
                    text = value
                        .map((val) => filter.tomselect.options[val].text)
                        .join(', ');
                }
                bindhq.tableListing.addCurrentFilter(
                    id,
                    indicatedAttr + text,
                    null,
                    removable,
                    container,
                );
            }
        } else {
            if (input.val() !== '') {
                text = input.data('indicated') + input.val();
                bindhq.tableListing.addCurrentFilter(
                    id,
                    text,
                    null,
                    removable,
                    container,
                );
            }
        }
    };

    bindhq.tableListing.addCurrentFilter = function (
        id,
        text,
        value,
        removable,
        container,
    ) {
        if (text) {
            const html = bindhq.util.template(bindhq.tpl.table_current_filter, {
                id: id,
                name: text.length > 40 ? text.substr(0, 40) + '&hellip;' : text,
                value: value,
                removable: removable,
            });

            $('.current-filters', container).append(html);
        }
    };

    bindhq.tableListing.onRemoveFilter = function (container, table, evt) {
        const element = $(evt.target).closest('.filtered');
        const id = element.data('input-id');
        const input = $('#' + id);
        if (input) {
            if (input.data('select2')) {
                if (bindhq.isGranted('FEATURE_MULTIPLE_FILTERS_LIST')) {
                    input.select2('data', null);
                    input.trigger('change');
                } else {
                    if ($.isArray(input.select2('data'))) {
                        const value = element.data('input-value')
                            ? element.data('input-value').toString()
                            : '';
                        const values = input.select2('val');
                        let i = values.indexOf(value);
                        if (i >= 0) {
                            i++;
                            $(
                                '.select2-choices li:nth-child(' + i + ') a',
                                input.parent(),
                            ).click();
                        }
                    } else {
                        if (input.select2('data')) {
                            input.select2('data', null);
                        }
                    }
                    input.trigger('change');
                }
            } else if (input.attr('type') === 'checkbox') {
                if (input.prop('checked')) {
                    input.prop('checked', false);
                }
            } else if (input.hasClass('hasDatepicker')) {
                input.parent().find('input').val('');
                input.trigger('change');
            } else if (input.get(0).tomselect) {
                input.get(0).tomselect.setValue(null);
            } else {
                input.val('');
                input.trigger('keyup');
            }
            if (input.hasClass('toggleable-checkbox')) {
                const inputSection = input.closest('.section');
                if (0 !== inputSection.length) {
                    inputSection.find('.toggleable').hide();
                    inputSection.find('input').val('');
                }
            }
        }

        bindhq.tableListing.onApplyFilter(container, table);

        if (bindhq.isGranted('FEATURE_FILTERS_ON_TRANSACTIONS')) {
            $(document).trigger(
                $.Event('table-listing.remove-filter', {
                    criteria: id,
                }),
            );
        }
    };

    bindhq.tableListing.onChangeContact = function (evt) {
        const option = $(evt.currentTarget);
        const email = option.select2().find(':selected').data('email');
        const container = option.closest('.contact-selector-container');
        if (email) {
            $('small', container).hide();
            $('a.email-link', container).attr('href', 'mailto:' + email);
            $('span.email-content', container).html(email);
            $('a.email-link', container).show();
        } else {
            $('a.email-link', container).hide();
            $('small', container).show();
        }
    };

    bindhq.tableListing.onClickFilterColumn = function (container, evt) {
        const label = $(evt.currentTarget);
        const criteria = label.data('sort');
        const orderInput = $('[name$="order]"]');

        if (criteria) {
            bindhq.tableListing.updateDirection(criteria, orderInput);
            orderInput.val(criteria);
            container.trigger('refreshPage');
        }
    };

    bindhq.tableListing.updateDirection = function (criteria, orderInput) {
        const directionInput = $('[name$="direction]"]');

        $('th.sort-column').removeClass('sort-ascending sort-descending');

        if (criteria === orderInput.val() && directionInput.val() === 'desc') {
            directionInput.val('asc');
            $('th.sort-column[data-sort*="' + criteria + '"]').addClass(
                'sort-ascending',
            );
        } else {
            directionInput.val('desc');
            $('th.sort-column[data-sort*="' + criteria + '"]').addClass(
                'sort-descending',
            );
        }
    };

    bindhq.tableListing.onDataLoaded = function (container, table) {
        const totalNum = table.data('paginationTotal');
        const totalNumContainer = $('span.total-num-container');

        bindhq.tableListing.updateFilterBadges(container);

        totalNumContainer.html(numeral(totalNum).format('0,000'));
        table.removeClass('row-expanded');
        $('.contact-selector-container .select2').select2();
    };

    bindhq.tableListing.displayFixedBottomRow = function (table) {
        const row = $('tr.table-fixed-bottom-row', table);
        const columns = row.find('th');
        const columnssWidth = _.map(columns, function (column, key) {
            if (key === 0) {
                const offset = $(column).offset().left;
                return $(column).width() + offset;
            } else {
                return $(column).width();
            }
        });
        row.addClass('table-fixed-bottom-row-visible');

        _.each(columns, function (column, key) {
            $(column).width(columnssWidth[key]);
        });
    };

    bindhq.tableListing.initDisplay = function () {
        const table = $(this);

        if (table.hasClass('tablesorter')) {
            let items;

            items = $('thead tr:last', table).find('th').not('.sorter-false');

            items = items.find('.tablesorter-header-inner').get(0)
                ? items.find('.tablesorter-header-inner')
                : items;
        }
    };

    bindhq.tableListing.init = function () {
        const container = $(this);
        const wrapper = container.closest('.table-listing-wrapper');
        const tableListingForm = container.closest('.table-listing-form');

        const onClickRow = _.partial(bindhq.tableListing.onClickRow, container);
        const onPin = _.partial(bindhq.tableListing.onPin, container);
        const onChangeContact = _.partial(bindhq.tableListing.onChangeContact);
        const onDataLoaded = _.partial(
            bindhq.tableListing.onDataLoaded,
            wrapper,
            container,
        );
        const onToggleFilter = _.partial(
            bindhq.tableListing.onToggleFilter,
            container,
        );
        const onClickOverlay = _.partial(
            bindhq.tableListing.onClickOverlay,
            container,
        );
        const onClickFilterColumn = _.partial(
            bindhq.tableListing.onClickFilterColumn,
            container,
        );
        const onRemoveFilter = _.partial(
            bindhq.tableListing.onRemoveFilter,
            wrapper,
            container,
        );
        const onApplyFilter = _.partial(
            bindhq.tableListing.onApplyFilter,
            wrapper,
            container,
        );
        const updateFilterBadges = _.partial(
            bindhq.tableListing.updateFilterBadges,
            wrapper,
        );

        container
            .find('.sort-column')
            .wrapInner("<div class='tablesorter-header-inner'></div>");

        container.on('click', '.sort-column', onClickFilterColumn);
        container.on('click', 'tr.expandable-row', onClickRow);
        container.on('click', '.pinner', onPin);
        container.on('change', '.contact-selector', onChangeContact);
        container.on('pagination:dataLoaded', onDataLoaded);

        wrapper.on('click', '.btn-apply-filter', onApplyFilter);
        wrapper.on('click', '.btn-filter', onToggleFilter);
        wrapper.on('click', '.table-listing-tools', onClickOverlay);
        wrapper.on('click', '.filtered .btn-icon-close', onRemoveFilter);

        tableListingForm.on('transaction_table.filtered', updateFilterBadges);
        tableListingForm.on('table-listing.filtered', updateFilterBadges);

        bindhq.tableListing.displayFixedBottomRow(container);

        setTimeout(updateFilterBadges, 50);

        const form = container.closest('form').get(0);

        if (
            form &&
            !form.checkValidity() &&
            !form.dataset.tableListingDisableInitialValidation
        ) {
            onToggleFilter();
        }
    };
})();
